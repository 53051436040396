import axios from "@/plugins/axios";
import qs from "qs";

export const getAllStoriesByType = async (type) => {
  const query = qs.stringify({
    populate: {
      image: "*",
      logo: "*",
      image_preview: "*",
      localizations: "*",
    },
    filters: {
      type: {
        $eq: type,
      },
    },
  });
  const response = await axios.get(`/stories?${query}`);
  return response.data.data;
};
