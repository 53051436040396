<template>
  <div v-if="stories" class="sp">
    <div class="sp__slider swiper">
      <swiper ref="swiperRef" :options="swiperOption">
        <swiper-slide
          v-for="(story, index) in stories"
          :key="index"
          :data-id="story.id"
          class="sp__slide-swiper"
        >
          <div class="sp__slide swiper-slide">
            <div class="sp__descr">
              <div class="sp__header">
                <h2
                  class="sp__title"
                  :style="{ backgroundColor: story.attributes.bg_color_1 }"
                >
                  {{ story.attributes.header }}
                </h2>
                <div class="sp__subtitle">
                  {{ story.attributes.slogan }}
                </div>
              </div>
              <div v-if="story.attributes.text" class="sp__item">
                <vue-markdown :source="text">
                  {{ story.attributes.text }}</vue-markdown
                >
              </div>
            </div>
            <div class="sp__img">
              <img
                class="sp__logo"
                src="@/assets/img/icons/magnum-chef.svg"
                alt=""
              />
              <img
                :src="getBigImage(story)"
                alt="slide image"
                class="desktop"
              />
              <img
                :src="getSmallImage(story)"
                alt="slide image"
                class="mobile"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="sp__slider-nav">
        <button class="sp__close-btn" @click="goBack">
          <svg
            width="62"
            height="62"
            viewBox="0 0 62 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
            <path
              d="M41.46 23.0606L39.3994 21L31.23 29.1694L23.0606 21L21 23.0606L29.1694 31.23L21 39.3994L23.0606 41.46L31.23 33.2906L39.3994 41.46L41.46 39.3994L33.2906 31.23L41.46 23.0606Z"
              fill="#B7B7B7"
            />
          </svg>
        </button>
        <div class="sp__arrows">
          <button
            @click.prevent="prevSlide"
            @mouseover.stop="addBg"
            @mouseleave.stop="removeBg"
            class="sp__prev-btn sp__slide-btn"
          >
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
              <path
                d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                fill="#B5B5B5"
              />
            </svg>
          </button>
          <button
            @click.prevent="nextSlide"
            @mouseover.stop="addBg"
            @mouseleave.stop="removeBg"
            class="sp__next-btn sp__slide-btn"
          >
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
              <path
                d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                fill="#B5B5B5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllStoriesByType } from "@/api/story";
import VueMarkdown from "vue-markdown";

export default {
  name: "OwnProduction",
  components: {
    VueMarkdown,
  },
  data() {
    return {
      stories: null,
      arrowStyle: null,
      swiperOption: {
        loop: true,
        spaceBetween: 30,
        slidesPerView: 1,
        responsive: true,
        observer: true,
        observeParents: true,
        parallax: true,
        loopedSlides: null,
        loopAdditionalSlides: 0,
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiperRef;
    },
    slides() {
      return this.$refs.swiperRef?.swiperInstance?.slides;
    },
  },
  created() {
    getAllStoriesByType("sp").then((stories) => {
      this.stories = stories;

      this.$nextTick(() => {
        this.swiper.swiperInstance.slides.forEach((e, i) => {
          if (parseInt(e.dataset.id) == this.$attrs.id) {
            this.swiper.swiperInstance.slideTo(i, 0, false);
          }
        });
      });
    });
  },
  mounted() {
    if (!("path" in Event.prototype))
      Object.defineProperty(Event.prototype, "path", {
        get: function () {
          var path = [];
          var currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }
          if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
            path.push(document);
          if (path.indexOf(window) === -1) path.push(window);
          return path;
        },
      });
  },

  methods: {
    prevSlide(e) {
      this.swiper.swiperInstance.slidePrev();
      let realIndex = this.swiper.swiperInstance.realIndex;
      let circle = e?.path[0];
      let arrow = e?.path[2]?.children[0]?.children[1];
      circle.style.fill = this.stories[realIndex]?.attributes?.bg_color_1;
      arrow.style.fill = "#fff";
    },
    nextSlide(e) {
      this.swiper.swiperInstance.slideNext();
      let realIndex = this.swiper.swiperInstance.realIndex;
      let circle = e?.path[0];
      let arrow = e?.path[2]?.children[0]?.children[1];
      circle.style.fill = this.stories[realIndex]?.attributes?.bg_color_1;
      arrow.style.fill = "#fff";
    },
    addBg(e) {
      let realIndex = this.swiper?.swiperInstance?.realIndex;
      let circle = e?.path[0];
      let arrow = e?.path[2]?.children[0]?.children[1];
      circle.style.fill = this.stories[realIndex]?.attributes?.bg_color_1;
      arrow.style.fill = "#fff";
    },
    removeBg(e) {
      let circle = e?.fromElement?.children[0]?.children[0];
      let arrow = e?.fromElement?.children[0]?.children[1];
      circle.style.fill = "#fff";
      arrow.style.fill = "#B5B5B5";
    },
    goBack() {
      this.$router.push({
        name: "Home",
        params: this.$route.params,
        query: this.$route.query,
      });
    },
    getBigImage(item) {
      const bigImage = item?.attributes?.image?.data?.attributes?.url;
      if (bigImage) {
        return this.$helpers.getAbsolutePath(bigImage);
      }
    },
    getSmallImage(item) {
      const smallImage = item?.attributes?.image?.data?.attributes?.url;
      if (smallImage) {
        return this.$helpers.getAbsolutePath(smallImage);
      }
    },
  },
};
</script>

<style scoped>
.swiper-container * {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-slide {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
</style>
